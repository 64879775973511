<template>
  <div>
    <h1>App Settings</h1>

        
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>