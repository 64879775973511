<template>
  <v-card>
    <v-tabs vertical color="blue-grey darken-2">
      <v-tab v-for="item in items" :key="item.tab">{{item.name}}</v-tab>

      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <v-card-text>
            <component :is="item.component" :key="item.name"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>
  </v-card>
</template>

<script>
  import AppSettings from "@/components/Admin/Settings/AppSettings";
  import Commerce from "@/components/Admin/Settings/Commerce";
  
  export default {
    components: {
      AppSettings,
      Commerce
    },
    data() {
      return {
        tab: null,
        items: [
          {tab: 'One', name: 'App', component: 'AppSettings'},
          {tab: 'Two', name: 'Commerce', component: 'Commerce'},
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>