<template>
  <div>
    <h1>Commerce Settings</h1>

    <v-form @submit.prevent="updateSettings">
      <h2 class="mt-5">Payfast Settings</h2>  
      <v-divider class="mt-3"></v-divider>
      <v-row>
        <v-col cols="6" 
          v-for="(textField, index) in payfastSettings" 
          :key="index">
          <v-text-field
            v-model="postData.payfast[textField.model]"
            :label="textField.label"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <h2 class="mt-3">Store Address</h2>
      <v-divider class="mt-3"></v-divider>
      <v-row>
        <v-col cols="6" 
          v-for="(textField, index) in storeAddress" 
          :key="index">
          <v-text-field
            v-model="postData.billing[textField.model]"
            :label="textField.label"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn type="submit" color="success" class="float-right mb-3">Save</v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

  export default {
    data() {
      return {
        postData: {
          payfast: {
            merchant_id: "",
            merchant_key: "",
            return_url: "",
            cancel_url: "",
            notify_url: "",
            passphrase: "",
          },
          billing: {
            addy1: "",
            addy2: "",
            city: "",
            country: "",
            postcode: ""
          }
        },

        payfastSettings: [
          {
            model: "merchant_id",
            label: "Merchant ID"
          },
          {
            model: "merchant_key",
            label: "Merchant Key"
          },
          {
            model: "return_url",
            label: "Return Url"
          },
          {
            model: "cancel_url",
            label: "Cancel Url"
          },
          {
            model: "notify_url",
            label: "Notify Url"
          },
          {
            model: "passphrase",
            label: "Passphrase"
          }
        ],

        storeAddress: [
          {
            model: "addy1",
            label: "Address Line 1"
          },
          {
            model: "addy2",
            label: "Address Line 2"
          },
          {
            model: "city",
            label: "City"
          },
          {
            model: "country",
            label: "Country"
          },
          {
            model: "postcode",
            label: "Postcode"
          },
        ]
      }
    },
    mounted () {
      this.postData = this.commerceSettings
    },
    methods: {
      ...mapActions('commerceSettings', ['save']),
      
      updateSettings() {
        this.save(this.postData)
          .then(response => {
            console.log("saved successfully")
          }).catch(err => {
            console.error(err)
          })
      }
    },
    computed: {
      ...mapState(['commerceSettings'])
    }
  }
</script>

<style lang="scss" scoped>

</style>